@import '~antd/dist/antd.css';


body,html{
  height: 100%;
  width: 100%;
}

.ant-carousel .slick-prev {
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(135deg);
  padding: 10px;
}

.ant-carousel .slick-next {
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(-45deg);
  padding: 10px;
}



body{
  background-image: url("./media/back7.jpg");
  background-size: cover;
  height: auto;
  width: 100vw;

}

.App{
  text-align: center;
}
  /* padding: 1.5em;
  text-align: center;
  background-image: url("./media/back4.jpg");
  width: 100vw;
  min-height: 100vh;
  max-height: auto;
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center; */




nav{
  opacity: .8;

}




nav >div> a{
  font-size: 1.4em;
font-family: Raleway,sans-serif;
cursor: pointer;
padding: 10px;
margin: 0 10px;
color: white;
opacity: .7;
font-weight: bold;

  text-transform: uppercase;
}

.ant-menu-item.info{
  grid-area: info;
  width: 100%;
}

.ant-menu-item.skillset{
  grid-area: skillset;
  margin-left: 20%;
  width: 100%;
}

.ant-menu-item.projects{
  grid-area: projects;
  width: 100%;
}

.ant-menu-item.contact{
  grid-area: contact;
  width: 100%;
}



.buttons >a{
  margin-left: 3%;

}

.buttons{
  grid-area: buttons;
  display: flex;
  justify-content: center;
  margin-top: 3%;

}




.icons{
  display: flex;

  flex-direction: column;
}

.ant-carousel .slick-slide {
  text-align: center;

  overflow: hidden;

  color:white;
}

.ant-card{

  margin-left:10%;
  margin-right:10%;
  margin-top:5%;
  background: #3C769F;
  opacity: .9;
}

.ant-card-body{
    text-align: center;
    /* margin: 0 auto; */
    margin-left: 12%;
    margin-right: 12%;
    color:white;
    opacity: .9;

}

.projecTags{
  font-size: 13px;
  color: #3C769F;
}

.ant-layout{
  background: white;
}

h1,h2{
  color:white;
}

.ant-card-head-wrapper{
  color:white;
}


.name{
  cursor: none!important;
  font-size: 1.4em;
  font-family: Raleway,sans-serif;
  cursor: pointer;
  padding: 10px;

  text-align: center;
  color: white;
  opacity: .7;
  font-weight: bold;
  text-transform: uppercase;
}


@media screen and (max-width: 370px){
  nav >div> a{
    font-size: .7em!important;
  font-family: Raleway,sans-serif;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px;
  color: white;
  opacity: .7;
  font-weight: bold;
  text-transform: uppercase;
  }

}



@media screen and (max-width: 450px){
  .ant-menu-item.image{
  grid-area: image;
  width: 90%;
  height: 80%;

  }
  /* body,html{
    height: 100%;
    width: 100%;
  }




  body{
    background-image: url("./media/back4.jpg");
    background-size: cover;
    height: auto;
    width: 100vw;

  }

  .App{
    text-align: center;
  } */
  .App{
    padding: 1.5em;
    text-align: center;
    background-image: url("./media/back4.jpg");
    width: 100vw;
    min-height: 100vh;
    max-height: auto;
    background-repeat: no-repeat;

    background-size: cover;
    background-position: center;

  }

  nav{
    opacity: .8;
    /* border: 1px solid black; */

  }




  nav >div> a{
    font-size: 1em;
  font-family: Raleway,sans-serif;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px;
  color: white;
  opacity: .7;
  font-weight: bold;

    text-transform: uppercase;
  }



  .projectsContainer {
    width: 100%;
    height: auto;
    /* border: 1px solid blue; */
  }

  .projectImages{
    /* border: 1px solid green; */
    height: 150px;
    width: 150px;
  }

  .ant-menu-item.image > img{
    margin-top: 10%;
    width: 70%;
    margin-left: 25%;
    border-radius: 40%;
  }

  .buttons{
    grid-area: buttons;



  }
  .buttons >a{
    margin-left: 3%;

  }

  .profileInfo{
    font-family: Raleway;
    padding: 3em;
  }
  .ant-tag {
    margin: 2% 2% 2% 2%;
  }

  .details{
    font-size: 1.1em;
  }

  .ant-card-head-title{
      text-align: center;
      font-size: 1.5em;
      color:white;
  }

  .profile{


    margin-top: 3%;
    display: grid;
    grid-template-areas:
    "aboutMe"
    "skills";
    color:black;
    text-align: center;
      font-family: Raleway,sans-serif;




  }

  .contacts{

    margin-top: 8%;
    background: #3C769F;
    /* margin: 0 auto; */
    /* margin-left: 20%; */
    /* margin-right: 20%; */
    border-radius: 20%;
    color: white;
    /* padding: 3em; */
    text-align: center;
    opacity: .8;

  }



  .aboutMe{
    grid-area: aboutMe;
background: #3C769F;
    display: grid;

    grid-template-areas: "img"
    "text";
    border-radius: 10%;
    opacity: .9;

  }

  .img{
    grid-area: img;
    display: grid;
    justify-self: center;
    align-self: center;

  }

  .img > img{
    width: 50%;
    border-radius: 50%;
    justify-self: center;
    align-self: center;
    margin-bottom: 1%;

  }

  .text{
    background: #3C769F;

    color: white;
    grid-area: text;
  font-family: Courier New;
    font-size: 1.1em;
    border-radius: 10%;
  font-family: Helvetica;
    padding: .2em;
    /* opacity: .9; */
    margin-bottom: 5%;
  }


  .skillInfo{
      opacity: .9;
      grid-area: skills;
  }

   .skillsets{

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }


  .skills{
    flex-grow: 1;
    width: 30%

  }

  .titles{
    margin-top: 2%;
    background: #3C769F;
    margin-left: 46%;
    margin-right: 46%;
    font-family: Raleway,sans-serif;
    text-decoration: underline;
    color:white;
    font-size: 2em;
    width: 100%;
    border-radius: 20%;
  }
.projectImages{
  /* border: 1px solid green; */
  width: 250px;
  height: 200px;
}
  .ant-card-body{
      text-align: center;
      /* border: 1px solid red; */
      /* width: 200px; */

      /* height: 200px; */
      /* width: 200px; */
      /* margin: 0 auto; */
      /* margin-left: 12%; */
      /* margin-right: 12%; */
      color:white;
      opacity: .9;

  }

}

@media screen and (min-width: 451px){
.ant-menu-item.image{
grid-area: image;
width: 100%;
height: 100%;

}



.ant-menu-item.image > img{
  width: 15%;
  margin-left: 43%;
  border-radius: 40%;

}

.details{
  font-size: 1.5em;
}

.projectImages{
  /* border: 1px solid green; */
  height: 350px;
  width: 350px;
}

.profile{


  margin-top: 1%;
  display: grid;
  grid-template-areas:
  "aboutMe"
  "skills";
  color:black;
  text-align: center;
    font-family: Raleway,sans-serif;


}

.aboutMe{
  grid-area: aboutMe;
  display: grid;
  grid-template-areas: "img text";

}


.img{
  grid-area: img;
  display: grid;
  /* border: 1px solid black; */
}

.img > img{
  width: 55%;
  /* border: 1px solid white; */
  /* border: 1px solid black; */
  border-radius: 50%;
  justify-self: center;
  align-self: center;

}

.titles{

  background: #3C769F;
  margin-left: 46%;
  margin-right: 46%;
  font-family: Raleway,sans-serif;
  text-decoration: underline;
  color:white;
  font-size: 2em;
  width: 100%;
  border-radius: 20%;
}

.text{

  background: white;
  color: #3C769F;
  grid-area: text;
font-family: Courier New;
margin-right: 8%;

  font-size: 1.4em;
  border-radius: 5%;
font-family: Helvetica;
  padding: .8em;
  opacity: .7

}


.skillInfo{
    opacity: .9;
    margin-top: 2%;
    grid-area: skills;
    justify-self: center;
    align-self: center;
}

 .skillsets{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}



.skills{
  flex-grow: 1;
  margin-top: 1%;
  width: 20%

}


.profileInfo{
  font-family: Raleway;
  padding: 3em;
  font-size: 1.7em;
}


.contacts{

  margin-top: 5%;
  background: #3C769F;
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 50%;
  color: white;
  padding: 3em;
  text-align: center;
  opacity: .8;

}


.ant-tag {
  margin: 2% 2% 2% 2%;
  font-size: 18px;
}

.ant-card-head-title{
    text-align: center;
    font-size: 1.5em;
}

}
